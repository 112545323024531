import React from "react";

function SectionDownload() {
  return (
    <section className="cta" id="download">
      <div className="cta-content">
        <div className="container px-5">
          <h2 className="display-1 text-white lh-1 mb-4">
            {" "}
            Stop your daily checks. <br />
            We'll notify you.{" "}
          </h2>
        </div>
      </div>
    </section>
  );
}

export default SectionDownload;
