import React from "react";

function MasterHeader() {
  return (
    <header className="masthead">
      <div className="container px-5">
        <div className="row gx-5 align-items-center">
          <div className="col-lg-6">
            <div className="text-center text-lg-start mb-5 mb-lg-0">
              <h1 className="display-1 lh-1 mb-3">Protect Your Investment.</h1>
              <p className="lead fw-normal text-muted mb-5">
              Automation Watchdog transforms the daily routine of RPA COEs by introducing an innovative way to monitor workflows. 
              Gone are the days of manual job queue reviews and outcome checks. 
              <p/>
              With Automation Watchdog, workflows become self-reporting entities, autonomously signaling their status and ensuring timely interventions only when necessary. 
              This leap forward ensures operational efficiency, reduces the chance of oversight, and allows your team to focus on tasks that truly require human insight.
              </p>
            </div>
          </div>
          <div className="col text-center">
            <img
              src="assets/img/watchdog.png"
              width="300"
              height="358"
              alt="Watchdog"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default MasterHeader;
