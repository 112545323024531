// import React from "react";

// function ModalContactUs() {
//   return (
//     <div
//       className="modal fade"
//       role="dialog"
//       tabIndex="-1"
//       id="feedbackModal"
//       aria-labelledby="feedbackModalLabel"
//       aria-hidden="true"
//     >
//       <div className="modal-dialog modal-dialog-centered" role="document">
//         <div className="modal-content">
//           <div className="modal-header bg-gradient-primary-to-secondary p-4">
//             <h5
//               className="text-white modal-title font-alt"
//               id="feedbackModalLabel"
//             >
//               Sign up for our beta program
//             </h5>
//             <button
//               type="button"
//               className="btn-close"
//               data-bs-dismiss="modal"
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body border-0 p-4">
//             <form id="SignUpForm">
//               
//               <div className="d-none" id="submitSuccessMessage">
//                 <div className="text-center mb-3">
//                   <div className="fw-bolder">
//                     <span>Form submission successful!</span>
//                   </div>
//                   <span> To activate this form, sign up at </span>
//                   <br />
//                   <a href="https://startbootstrap.com/solution/contact-forms">
//                     https://startbootstrap.com/solution/contact-forms
//                   </a>
//                 </div>
//               </div>
//               <div className="d-none" id="submitErrorMessage">
//                 <div className="text-center text-danger mb-3">
//                   <span>Error sending message!</span>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ModalContactUs;

import React, {useState} from 'react'
import axios from 'axios';

const ModalContactUs = () => {
    const [values, setValues] = useState({name: '', message: '', email: '', referral_code: ''})
    const [sent, setSent] = useState(false);
    const handleChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            await axios.post(
                "https://analytics.fuhso.com/open/workspace/cm1tlddiy0001l2k6kv3m0n0f/survey/cm2gs28ig00xhhnk1uxgple2t/submit",
                {
                    payload: {
                        "field_name": values.name,
                        "field_email": values.email,
                        "field_referral_code": values.referral_code,
                        "field_message": values.message
                    }
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            )
        } catch (error) {
            console.error(error);
        }
        setSent(true);
    }

    if (!sent) {
        return (
            <div
                className="modal fade"
                role="dialog"
                tabIndex="-1"
                id="contactUsModal"
                aria-labelledby="contactUsModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-gradient-primary-to-secondary p-4">
                            <h5
                                className="text-white modal-title font-alt"
                                id="feedbackModalLabel"
                            >
                                Contact Us
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body border-0 p-4">
                            <div style={{paddingLeft: '1vw',}}>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control form-control"
                                            type="text"
                                            id="name"
                                            placeholder="Enter your name..."
                                            data-sb-validations="required"
                                            name="name"
                                            onChange={handleChange}
                                            value={values.name}
                                        />
                                        <label className="form-label" htmlFor="name">
                                            Full name *
                                        </label>
                                        <div
                                            className="invalid-feedback"
                                            data-sb-feedback="name:required"
                                        >
                                            <span>A name is required.</span>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control form-control"
                                            type="email"
                                            id="email"
                                            name="email"
                                            onChange={handleChange}
                                            value={values.email}
                                            placeholder="name@example.com"
                                            data-sb-validations="required,email"
                                        />
                                        <label className="form-label" htmlFor="email">
                                            Email address *
                                        </label>
                                        <div
                                            className="invalid-feedback"
                                            data-sb-feedback="email:required"
                                        >
                                            <span>An email is required.</span>
                                        </div>
                                        <div
                                            className="invalid-feedback"
                                            data-sb-feedback="email:email"
                                        >
                                            <span>Email is not valid.</span>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control form-control"
                                            type="text"
                                            id="referral_code"
                                            placeholder="Enter your referral code..."
                                            name="referral_code"
                                            onChange={handleChange}
                                            value={values.referral_code}
                                        />
                                        <label className="form-label" htmlFor="referral_code">
                                            Referral code
                                        </label>
                                    </div>
                                    <div className="form-floating mb-3">
                    <textarea
                        className="form-control form-control" id="message"
                        type="text"
                        placeholder="Enter your message here..."
                        style={{height: "10rem"}}
                        data-sb-validations="required"
                        name="message"
                        onChange={handleChange}
                        value={values.message}
                    ></textarea>
                                        <label className="form-label" htmlFor="message">
                                            Message *
                                        </label>
                                        <div
                                            className="invalid-feedback"
                                            data-sb-feedback="message:required"
                                        >
                                            <span>A message is required.</span>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button
                                            className="btn btn-primary btn-lg rounded-pill" id="submitButton"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="modal fade"
                role="dialog"
                tabIndex="-1"
                id="contactUsModal"
                aria-labelledby="contactUsModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-gradient-primary-to-secondary p-4">
                            <h5
                                className="text-white modal-title font-alt"
                                id="feedbackModalLabel"
                            >
                                Contact Us
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body border-0 p-4">
                            <div style={{paddingLeft: '1vw',}}>
                                <p>Thank you for your interest in our product!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalContactUs;
