import MainNavBar from './components/MainNavbar';
import MainHeader from './components/MainHeader';
import SectionFeatures from './components/SectionFeatures';
import SectionPurpose from './components/SectionPurpose';
import SectionDownload from './components/SectionDownload';
import MainFooter from './components/MainFooter';
import ModalContactUs from './components/ModalContactUs';
import './App.css';

function App() {
  return (
    <div className="App" id="page-top">
      <MainNavBar />
      <MainHeader/>
      <SectionFeatures/>
      <SectionPurpose/>
      <SectionDownload/>
      <MainFooter/>
      <ModalContactUs/>
    </div>
  );
}

export default App;
