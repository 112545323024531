import React from "react";

function Navbar() {
    return (
        <nav
            className="navbar navbar-light navbar-expand-lg fixed-top shadow-sm"
            id="mainNav"
        >
            <div className="container px-5">
                <a className="navbar-brand fw-bold" href="#page-top">
                    <img src="/assets/img/automation-watchdog-logo.svg" alt="Automation Watchdog logo" width={40}
                         className="logo"/> Automation Watchdog
                </a>
                <button
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive"
                    className="navbar-toggler"
                    type="button"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span> Menu </span>
                    <i className="bi-list"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                        <li className="nav-item">
                            <a className="nav-link me-lg-3" href="#features">
                                Features
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link me-lg-3"
                                href="https://docs.automationwatchdog.com/?utm_source=landing_page&utm_medium=navbar_link&utm_campaign=landing_page&utm_content=navbar_link"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Documentation
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link me-lg-3"
                               href="#!"
                               style={{cursor: "pointer"}}
                               data-bs-toggle="modal"
                               data-bs-target="#contactUsModal">
                                Contact Us
                            </a>
                        </li>
                    </ul>

                    <a
                        className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
                        href="https://app.automationwatchdog.com/login?utm_source=landing_page&utm_medium=navbar_button&utm_campaign=landing_page&utm_content=navbar_button"
                        target="_blank"
                        rel="noreferrer"
                    >
            <span className="d-flex align-items-center">
              <i className="bi-person-fill me-2"></i>
              <span className="small">Login</span>
            </span>
                    </a>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
