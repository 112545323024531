import React from "react";

function MainFooter() {
    return (
        <footer className="text-center bg-black py-5">
            <div className="container px-5">
                <div className="text-white-50 small">
                    <div className="mb-2"><span>© Automation Watchdog 2024. All Rights Reserved.</span></div><a href="#!">Privacy</a><span className="mx-1">·</span><a href="#!">Terms</a><span className="mx-1">·</span><a href="#!">FAQ</a>
                </div>
            </div>
        </footer>
    )
}

export default MainFooter;