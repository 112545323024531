import React from "react";

function SectionFeatures() {
    return (
        <section id="features">
            <div className="container px-5">
                <div className="row gx-5 align-items-center">
                    <div className="col-lg-12 order-lg-1 mb-5 mb-lg-0">
                        <div className="container-fluid px-5">
                            <div className="row gx-5">
                                <div className="col-md-4 mb-5">
                                    <div className="text-center"><i className="d-block bi-calendar2-check icon-feature icon-gradient mb-3"></i>
                                        <h3 className="font-alt">Flexible Monitoring</h3>
                                        <p className="text-muted mb-0">Monitor both scheduled and triggered workflows for execution.
                                            Supports Dispatcher/Performer/Reporter pattern.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="text-center"><i className="d-block bi-calendar3-range icon-feature icon-gradient mb-3"></i>
                                        <h3 className="font-alt">Elapsed Time or Fixed Period Check-ins</h3>
                                        <p className="text-muted mb-0">Ensure workflows are completing work within the maximumum allowable time or adhere to set time frames.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="text-center"><i className="d-block bi-shield-lock-fill icon-feature icon-gradient mb-3"></i>
                                        <h3 className="font-alt">Secure & Private</h3>
                                        <p className="text-muted mb-0">API-driven check-ins, offering complete data control through a push-based solution for enhanced privacy and efficiency</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="text-center"><i className="d-block bi-broadcast icon-feature icon-gradient mb-3"></i>
                                        <h3 className="font-alt">Alerting</h3>
                                        <p className="text-muted mb-0">Stay informed with real-time email alerts, ensuring immediate notification for critical workflow events.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5 mb-md-0">
                                    <div className="text-center"><i className="d-block bi-collection icon-feature icon-gradient mb-3"></i>
                                        <h3 className="font-alt">Multi-Machine Workflows</h3>
                                        <p className="text-muted mb-0">Enhance your operational scope with robust support for workflows spanning multiple machines.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="text-center"><i className="d-block bi-patch-check icon-feature icon-gradient mb-3"></i>
                                        <h3 className="font-alt">Monitoring as a Service</h3>
                                        <p className="text-muted mb-0">Externalize your oversight with our Monitoring as a Service, offering a seamless, external solution that enhances visibility with minimal intrusion.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            </div>
                        </div>
                    </div>
                    {/*
                <div className="col-lg-4 order-lg-0">
                    <div className="features-device-mockup"></div>
                </div>
                */}
                </div>
            </div>
        </section>
    );
}

export default SectionFeatures;