import React from "react";

function SectionPurpose() {
  return (
    <section className="bg-light">
      <div className="container px-5">
        <div className="row gx-5 justify-content-center align-items-center justify-content-lg-between">
          <div className="col-12 col-lg-5">
            <h2 className="display-4 lh-1 mb-4">
              A simplified experience for peace of mind.
            </h2>
            <p className="lead fw-normal text-muted mb-5 mb-lg-0">
              RPA currently has a blind spot in its monitoring alerting. Worry no more, Automation Watchdog has you covered.
            </p>
          </div>
          <div className="col-sm-8 col-md-6">
            <div className="px-5 px-sm-0">
              <img                
                src="assets/img/awd-watches-analytics-acreenshot.png"
                alt="screenshot of Automation Watchdog - Watch Analytics"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionPurpose;
